<template>
  <div class="public-header-wrap">
    <public-bread-crumb :bread-crumb="navigatorData" />
    <div class="title-box">
      <div class="title container">
        {{ titleData.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
export default {
  components: {
    PublicBreadCrumb
  },
  props: {
    navigatorData: {
      type: Array,
      default: () => []
    },
    titleData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
  }
}
</script>

<style lang="less" scoped>
.public-header-wrap {
  margin-top: 79px;
  border-top: 1px solid #ddd;
   /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
  .title-box {
    padding: 8px 0 16px 0;
    border-bottom: 1px solid #ddd;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #232931;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 1024px) {
    margin-top: 94px;
  }
  @media screen and (max-width: 768px) {
    .title-box {
      padding: 12px 0;
    }
  }
}
</style>
