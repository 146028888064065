<template>
  <div class="support-wrap">
    <public-banner :banner-info="bannerInfo" />
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="supportData" site="left" />
    <div class="support-detail container">
      <ul class="detail-list">
        <li v-for="(item, index) in detailList" :key="index" class="detail-item clearfix">
          <router-link class="link clearfix" :to="`/${$route.params.site}/${$route.params.lang}/${item.nav_link_name}`">
            <div :class="['content-box', index % 2 ? 'right' : 'left']">
              <i :class="['iconfont', item.nav_iconfont]"></i>
              <div class="title">{{ item.nav_name }}</div>
              <div class="des">
                {{ item.nav_description }}
              </div>
            </div>
            <div :class="['img-box', index % 2 ? 'left' : 'right']">
              <div class="bg-img" :style="`background: url(${item.nav_image}) center center / cover no-repeat;`"></div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getSupportInfo } from '@/api/support'
import { mapState } from 'vuex'
export default {
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {},
      supportData: {
        title: ''
      },
      detailList: []
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    await getSupportInfo().then(res => {
      this.detailList = res.data.support
      this.supportData.title = res.data.nav_name
      this.$store.commit('common/SET_MATE', res.data.seo)
    })
    await this.$store.dispatch('common/getNavigator', { type: 'support_list', id: 1, level: 1 })
    if (this.$route.fullPath.includes('business')) {
      await this.$store.dispatch('common/getBanner', { banner_id: 191 })
    } else {
      await this.$store.dispatch('common/getBanner', { banner_id: 192 })
    }
    if (this.banner) {
      this.bannerInfo = this.banner[0]
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.support-wrap {
   /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
   /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 8px 0 16px 0;
      .title {
        font-size: 24px;
      }
    }
  }
  .support-detail {
    padding: 72px 0;
    .detail-list {
      .detail-item {
        margin-bottom: 42px;
        cursor: pointer;
        .link {
          display: block;
          .content-box {
            float: left;
            width: 40.9%;
            padding: 47px 0;
            .iconfont {
              font-size: 40px;
              color: #A4A7AC;
            }
            .title {
              margin: 24px 0;
              font-size: 24px;
              font-weight: bold;
              color: #232931;
              line-height: 18px;
              .transition;
            }
            .des {
              font-size: 14px;
              color: #606266;
              line-height: 19px;
            }
          }
          .img-box {
            float: right;
            width: 40.9%;
            height: 240px;
            border-radius: 12px;
            overflow: hidden;
            .bg-img {
              width: 100%;
              height: 240px;
              .transition;
            }
            &:hover {
              .bg-img {
                .scale();
              }
            }
          }
          .right {
            float: right;
          }
          .left {
            float: left;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            .img-box {
              img {
                .scale
              }
            }
            .content-box {
              .title {
                color: @theme;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    .support-detail {
      .detail-list {
        .detail-item {
          .link {
            .content-box {
              width: 45%;
            }
            .img-box {
              width: 45%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .support-detail {
      .detail-list {
        .detail-item {
          .link {
            .content-box {
              width: 48%;
            }
            .img-box {
              width: 48%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        .title {
          font-size: 20px;
        }
      }
    }
    .support-detail {
      padding: 40px 0;
      .detail-list {
        .detail-item {
          .link {
            .content-box {
              width: 100%;
              padding: 0;
              .iconfont {
                font-size: 28px;
              }
              .title {
                font-size: 20px;
                margin: 12px 0;
              }
            }
            .img-box {
              width: 100%;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
