<template>
  <div class="service-policy-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div v-if="Object.keys(dataInfo).length" class="service-content container">
      <!-- Service Policy -->
      <public-title :data-info="publicTitle" />
      <div v-for="(item, index) in dataInfo.content.service_policy" :key="index" class="service-policy" @click="handleClick(item)">
        <div class="img-box">
          <div :style="`background: url(${item.image}) center center / cover no-repeat`" class="img-bg"></div>
        </div>
        <div class="text-box">
          <h5 class="title">{{ item.name }}</h5>
          <p class="desc" v-html="item.content"></p>
          <el-button class="btn-box" type="primary">{{ LP.lang_learn_more }}</el-button>
        </div>
      </div>
      <!-- Contact Customer Service -->
      <public-title :data-info="publicTitle2" />
      <div class="contact-customer">
        <ul class="list-box">
          <li v-for="(item, index) in dataInfo.content.contact_customer_service" :key="index" class="items">
            <i class="iconfont contact-icon" :class="item.icon"></i>
            <h5 class="name">{{ item.name }}</h5>
            <p class="desc">{{ item.content }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getServicePolicy } from '@/api/support'
export default {
  components: {
    PublicBanner,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {},
      publicTitle: {
        title: this.LP.lang_service_policy
      },
      publicTitle2: {
        title: this.LP.lang_contact_customer
      },
      dataInfo: {}
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner
    })
  },
  async mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    // banner数据
    if (this.$route.fullPath.includes('business')) {
      await this.$store.dispatch('common/getBanner', { banner_id: 252 })
    } else {
      await this.$store.dispatch('common/getBanner', { banner_id: 253 })
    }
    this.bannerInfo = this.banner[0]
    // 服务政策页面数据
    await getServicePolicy().then(res => {
      this.$store.commit('common/SET_MATE', res.data.seo)
      this.dataInfo = res.data
    })
  },
  methods: {
    // 跳转
    handleClick(item) {
      window.open(`/${this.$route.params.site}/${this.$route.params.lang}${item.link}`)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.service-policy-wrap {
  padding-bottom: 72px;
  .service-content {
    .service-policy {
      display: flex;
      align-items: center;
      background: #F5F5F5;
      border-radius: 10px;
      cursor: pointer;
      & + .service-policy {
        margin-top: 16px;
      }
      .img-box {
        width: 58%;
        max-width: 740px;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
        .img-bg {
          height: 310px;
          .transition();
        }
      }
      .text-box {
        width: 42%;
        padding: 0 172px 0 70px;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #232931;
          line-height: 18px;
          .transition();
        }
        .desc {
          font-size: 14px;
          color: #666666;
          line-height: 21px;
          margin-top: 10px;
          text-align: justify;
          max-height: 109px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .btn-box {
          margin-top: 74px;
        }
        /deep/ .el-button {
          font-weight: 700;
        }
      }
      &:hover {
        .img-box {
          .img-bg {
            .scale();
          }
        }
        .text-box {
          .title {
            color: @theme;
          }
        }
      }
    }
    .contact-customer {
      .list-box {
        display: flex;
        .items {
          width: 50%;
          text-align: center;
          padding: 0 76px;
          .contact-icon {
            font-size: 160px;
            color: #999999;
          }
          .name {
            font-size: 18px;
            font-weight: bold;
            color: #232931;
            line-height: 18px;
            margin: 16px 0 14px 0;
          }
          .desc {
            font-size: 14px;
            color: #606266;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .service-content {
      .service-policy {
        .text-box {
          padding: 0 70px 0 40px;
          .btn-box {
            margin-top: 46px;
          }
        }
      }
      .contact-customer {
        .list-box {
          .items {
            .contact-icon {
              font-size: 120px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .service-content {
      .service-policy {
        .text-box {
          padding: 0 40px;
          .btn-box {
            margin-top: 30px;
          }
        }
      }
      .contact-customer {
        .list-box {
          .items {
            .contact-icon {
              font-size: 80px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 40px;
    .service-content {
      .service-policy {
        flex-wrap: wrap;
        .img-box {
          width: 100%;
          .img-bg {
            height: 200px;
            border-radius: 10px 10px 0 0;
          }
        }
        .text-box {
          width: 100%;
          padding: 24px 16px;
        }
      }
      .contact-customer {
        .list-box {
          flex-wrap: wrap;
          .items {
            width: 100%;
            padding: 0;
            & + .items {
              margin-top: 24px;
            }
            .contact-icon {
              font-size: 40px;
            }
            .name {
              font-size: 16px;
              margin: 12px 0 10px 0;
            }
            .desc {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
