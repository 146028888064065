<template>
  <div class="support-tools-wrap">
    <public-header :navigator-data="navigator" :title-data="toolsData" />
    <public-title :data-info="toolsData" />
    <ul v-if="toolsList.length" class="tools-list">
      <li
        v-for="(item, index) in toolsList"
        :key="index"
        :class="['tools-item' ,'clearfix', {'rotate' : index % 2 }]"
        :style="`background: url(${item.image}) center center / cover no-repeat;`"
      >
        <div class="container">
          <div class="mobile-img">
            <img :src="item.image" alt="" />
          </div>
          <div :class="['left', {'float-left' : index % 2 }]">
            <div class="tools-title">
              {{ item.title }}
            </div>
            <div class="tools-des">
              {{ item.description }}
            </div>
            <span class="tools-btn" @click="downloadHandler(item)">
              {{ item.lang_start_now }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PublicHeader from '@/components/support/PublicHeader.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { getSuportTools } from '@/api/support'
import { mapState } from 'vuex'

export default {
  components: {
    PublicHeader,
    PublicTitle
  },
  data() {
    return {
      toolsData: {
        title: '',
        desc: ''
      },
      toolsList: []
    }
  },
  computed: {
    ...mapState({
      navigator: state => state.common.navigator
    })
  },
  async created() {
    await getSuportTools().then(res => {
      if (res.data.nav_tools) {
        ({ nav_name: this.toolsData.title, nav_description: this.toolsData.desc } = res.data.nav_tools)
      }
      this.$store.commit('common/SET_MATE', res.data.seo)
      this.toolsList = res.data.Tools
    })
    await this.$store.dispatch('common/getNavigator', { type: 'tools', id: 1, level: 1 })
  },
  methods: {
    // 下载
    downloadHandler(item) {
      window.open(item.tool_link, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.support-tools-wrap {
   /deep/ .public-title {
    .text-box {
      padding: 23px 0 54px;
      .title {
        font-size: 42px;
        line-height: 56px;
      }
      .desc {
        max-width: 740px;
        margin: 26px auto 0;
      }
    }
  }
  .tools-list {
    .tools-item {
      padding: 80px 0 60px;
      margin-bottom: 21px;
      .mobile-img {
        display: none;
        margin-bottom: 16px;
        &>img {
          width: 100%;
        }
      }
      .left {
        max-width: 420px;
        .tools-title {
          font-size: 24px;
          font-weight: bold;
          color: #232931;
          line-height: 32px;
        }
        .tools-des {
          margin: 46px 0 50px;
          font-size: 14px;
          color: #666666;
          line-height: 19px;
        }
        .tools-btn {
          cursor: pointer;
          display: inline-block;
          padding: 10px 20px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 21px;
          background-color: #0E5FAC;
          border-radius: 4px;
        }
      }
      .float-left {
        float: left;
        transform: rotate3d(0, 1, 0, 180deg);
      }
    }
    .rotate {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }
  @media screen and (max-width: 1024px) {
    /deep/ .public-title {
      .text-box {
        padding: 30px 0 24px;
        .title {
          font-size: 24px;
          line-height: 32px;
        }
        .desc {
          margin-top: 16px;
        }
      }
    }
    .tools-list {
      .tools-item {
        padding: 40px 0 30px;
        .left {
          .tools-title {
            font-size: 20px;
            line-height: 28px;
          }
          .tools-des {
            margin: 23px 0 25px;
          }
          .tools-btn {
            padding: 5px 10px;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tools-list {
      .tools-item {
        background: none !important;
        .mobile-img {
          display: block;
        }
      }
    }
  }
}
</style>
