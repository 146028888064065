<template>
  <div class="support-download-wrap">
    <public-header :navigator-data="navigator" :title-data="downloadData" />
    <public-title :data-info="downloadData" />
    <public-tabs :data-info="downloadList" :default-index="defaultIndex" @handleTab="handleTabs" />
    <div
      v-loading="loading"
      :element-loading-text="LP.lang_loading"
      element-loading-spinner="el-icon-loading"
      class="download-list-box"
    >
      <div class="container">
        <ul class="download-list">
          <li v-for="(item, index) in showDownloadList" :key="index" class="download-item" @click="downHandler(item)">
            <div class="left">
              <i class="iconfont icon-pdf"></i>
              <span class="file-name ellipsis">
                {{ item.title }}
              </span>
            </div>
            <div class="right">
              <i class="iconfont icon-a-xiazai1x"></i>
            </div>
          </li>
        </ul>
        <div v-if="total > 10" class="public-pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 未登录提示 -->
    <download-dialog :download-visible="downloadVisible" @close="handleClose" />
  </div>
</template>

<script>
import PublicHeader from '@/components/support/PublicHeader.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import PublicTabs from '@/components/public/PublicTabs.vue'
import { getDownloadCenter } from '@/api/support'
import { mapState } from 'vuex'
import DownloadDialog from '@/components/support/DownloadDialog.vue'

export default {
  components: {
    PublicHeader,
    PublicTitle,
    PublicTabs,
    DownloadDialog
  },
  data() {
    return {
      downloadData: {
        title: '',
        desc: ''
      },
      downloadList: [],
      showDownloadList: [],
      total: 0,
      downloadVisible: false, // 未登录提示弹窗
      loading: false,
      defaultIndex: 0 // 当前选中tab
    }
  },
  computed: {
    ...mapState({
      navigator: state => state.common.navigator
    })
  },
  async mounted() {
    this.getDownList()
    await this.$store.dispatch('common/getNavigator', { type: 'download', id: 1, level: 1 })
  },
  methods: {
    // 获取列表
    getDownList(menu_id, page = 1, limit = 10) {
      this.loading = true
      getDownloadCenter({
        menu_id,
        page,
        limit
      }).then(res => {
        ({ nav_name: this.downloadData.title, nav_description: this.downloadData.desc } = res.data.nav_download_center)
        this.downloadList = res.data.download_menu.map(item => ({
          ...item,
          title: item.menu_name
        }))
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.showDownloadList = res.data.download_list
        this.total = res.data.count
        this.loading = false
      })
    },
    // tabs切换
    handleTabs(item, index) {
      this.showDownloadList = item.download_list
      this.getDownList(item.menu_id)
      this.defaultIndex = index
    },
    // 下载
    downHandler(item) {
      if (item.power === '1') {
        this.downloadVisible = true
      } else {
        window.open(item.download)
      }
    },
    // 分页切换
    handleCurrentChange(val) {
      this.getDownList(this.menu_id, val)
    },
    // 关闭弹窗
    handleClose() {
      this.downloadVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.support-download-wrap {
  /deep/ .public-title {
    .text-box {
      padding: 48px 0 54px;
      .title {
        font-size: 42px;
        line-height: 42px;
      }
      .desc {
        max-width: 740px;
        margin: 16px auto 0;
      }
    }
  }
  /deep/ .public-nav-page {
    .public-nav {
      border-top: 1px solid #ddd;
      .link-container {
        margin-left: 0;
        .link-inside-container {
          margin-left: 0;
          .swiper-wrapper {
            justify-content: center;
          }
        }
      }
    }
  }
  .download-list-box {
    padding: 24px 0;
    background-color: #F4F4F5;
    .download-list {
      .download-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 10px;
        padding: 18px 30px 18px 21px;
        background-color: #fff;
        border-radius: 10px;
        .transition();
        &:hover {
          .shadow();
        }
        .left {
          width: calc(100% - 34px);
          display: flex;
          align-items: center;
          .file-name {
            display: inline-block;
            margin-left: 13px;
            font-size: 14px;
            color: #303133;
            line-height: 19px;
            .transition;
          }
        }
        .left,
        .right {
          .iconfont {
            font-size: 24px;
            .transition;
          }
        }
        &:hover {
          .left {
            .file-name {
              color: @theme;
            }
          }
          .left,
           .right {
             .iconfont {
               color: @theme
             }
           }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    /deep/ .public-title {
      .text-box {
        padding: 30px 0 24px;
        .title {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
